import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Image from '~components/Image'

const AboutPage = ({ data }) => {

	const { page } = data

	return (
		<Wrap >
			<Section>
				<PageHeader>
					<h2>{page.title}</h2>
				</PageHeader>
				<ImageCol>
					<Image image={page.imageAndCaption}/>
					<Caption><RichText content={page?.imageAndCaption.caption}/></Caption>
				</ImageCol>
				<Text>
					<RichText content={page.text}/>
				</Text>
			</Section>
			<DataViewer data={page}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: var(--grey);
	padding-top: 34px;
	padding-bottom: 44px;
	${mobile} {
		padding-top: 20px;
		padding-bottom: 36px;
	}
`
const PageHeader = styled.div`
	padding-bottom: var(--heading-pb);
	border-bottom: var(--heading-border) solid var(--blue);
	margin-bottom: 26px;
	grid-column: span 12;
	${mobile} {
		margin-bottom: 23px;
	}
`
const ImageCol = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 26px;
	}
`
const Caption = styled.h3`
	color: var(--blue);
	font-family: var(--sans);
	font-size: var(--sans-accent-lrg-size);
	line-height: var(--sans-accent-lrg-line);
	letter-spacing: var(--letter-spacing);
	text-transform: uppercase;
	margin-top: 14px;
`
const Text = styled.div`
	grid-column: 8/13;
	max-width: 570px;
	${mobile}{
		max-width: 100%;
		grid-column: span 12;
	}
`

export const query = graphql`
	query AboutQuery {
		page: sanityAboutPage {
			title
			text: _rawText(resolveReferences: { maxDepth: 5 })
			imageAndCaption {
				...imageWithCaption
			}
			seo {
				...seo
			}
		}
	}
`

AboutPage.propTypes = {
	data: PropTypes.object
}

export default AboutPage